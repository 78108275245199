/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

// You can delete this file if you're not using it

// require("./landkit/fonts/Feather/feather.css")
// require("./landkit/dist/assets/libs/@fancyapps/fancybox/dist/jquery.fancybox.min.css")
// require("./landkit/dist/assets/libs/aos/dist/aos.css")
// require("./landkit/dist/assets/libs/choices.js/public/assets/styles/choices.min.css")
// require("./landkit/dist/assets/libs/flickity-fade/flickity-fade.css")
// require("./landkit/dist/assets/libs/flickity/dist/flickity.min.css")
// require("./landkit/dist/assets/libs/highlightjs/styles/vs2015.css")
// require("./landkit/dist/assets/libs/jarallax/dist/jarallax.css")
// require("./landkit/dist/assets/libs/quill/dist/quill.core.css")
require("./landkit/assets/css/libs.bundle.css")
require("./landkit/assets/css/theme.bundle.css")
// require("./landkit/assets//theme.min.css")

// require("./landkit/dist/assets/libs/jquery/dist/jquery.min.js")
// require("./landkit/dist/assets/libs/bootstrap/dist/js/bootstrap.bundle.min.js")


// if (typeof window !== 'undefined') {
//   // window.jQuery = window.$ = require('jquery');
//   window.jQuery = window.$ = require("./landkit/dist/assets/libs/jquery/dist/jquery.min.js");
//   // require('bootstrap');
//   require("./landkit/dist/assets/libs/bootstrap/dist/js/bootstrap.bundle.min.js");
// }
